// attached to leave period request modal form

import { Controller } from '@hotwired/stimulus'
import moment from 'moment'
import { Eleave } from 'custom/eleave/leave_period_allowance_estimator'

export default class extends Controller {
  static targets = [ 'typeSelect', 'typeContainer', 'allowanceSelect', 'daysTaken', 'takenOrAccruedLabel', 'takenExplanation',
                     'fromDate', 'untilDate', 'fromDateWarning', 'requestedAtWarning', 'overbookingWarning' ]

  static values = { fromDate: String, untilDate: String, startTime: String, endTime: String,
                    units: String, type: String,
                    upcomingLeaveWarningWeeks: { type: Number },
                    hasErosteringIntegration: { type: Boolean, default: false },
                    requestedAt: String,
                    checkOverbooking: { type: Boolean, default: false },
                    allowanceOverriden: { type: Boolean, default: false }, // if set allowance hours is not updated when changing start/end time
                    publicHolidayDates: Array,
                    allowancesDaysRemaining: { type: Object, default: {} } }

  connect() {
    if(!this.untilDateValue) {
      this.untilDateValue = this.fromDateValue
    }

    this.#setCurrentAllowanceDaysRemaining()

    document.addEventListener('tomselect:initialized', (e) => {
      const el = document.querySelector(e.detail.selector)

      if(el == this.allowanceSelectTarget) {
        this.#allowanceUpdateOptions()
      }
    })

    this.handleRequestedAtWarning(this.fromDateTarget.value, this.reqestedAtValue)

    this.allowanceOverridenValue = false // it is set to true for existing record

    this.#processOverbooking()
  }

  #setCurrentAllowanceDaysRemaining() {
    const selectedOption = this.allowanceSelectTarget.options[this.allowanceSelectTarget.selectedIndex]
    this.currentAllowanceDaysRemaining = this.allowancesDaysRemainingValue[selectedOption.value]
  }

  fromDateChanged(event) {
    this.handleFromDateChanged(event.target.value)
  }

  untilDateChanged(event) {
    this.handleUntilDateChanged(event.target.value)
  }

  typeValueChanged() {
    this.#estimateAndUpdateAllowance()
  }

  fromDateValueChanged() {
    this.#estimateAndUpdateAllowance()

    if(this.upcomingLeaveWarningWeeksValue && this.hasFromDateWarningTarget) {
      if(this.#isFromDateUpcoming(this.fromDateValue)) {
        this.fromDateWarningTarget.classList.remove('d-none')
      } else {
        this.fromDateWarningTarget.classList.add('d-none')
      }
    }

    if(this.hasErosteringIntegrationValue) {
      const fromDateMoment = moment(this.fromDateValue, 'DD/MM/YYYY')
      const fromDateFormatted = fromDateMoment.format('YYYY-MM-DD')
      this.dispatch('range-changed', { detail: { fromDate: fromDateFormatted } })
    }
  }

  untilDateValueChanged() {
    if(this.hasErosteringIntegrationValue) {
      const untilDateMoment = moment(this.untilDateValue, 'DD/MM/YYYY')
      const untilDateFormatted = untilDateMoment.format('YYYY-MM-DD')
      this.dispatch('range-changed', { detail: { untilDate: untilDateFormatted } })
    }

    this.#estimateAndUpdateAllowance()
  }

  startTimeValueChanged() {
    if(this.hasErosteringIntegrationValue) {
      this.dispatch('range-changed', { detail: { startTime: this.startTimeValue } })
    }

    this.#estimateAndUpdateAllowance()
  }

  endTimeValueChanged() {
    if(this.hasErosteringIntegrationValue) {
      this.dispatch('range-changed', { detail: { endTime: this.endTimeValue } })
    }

    this.#estimateAndUpdateAllowance()
  }

  handleRequestedAtWarning() {
    if(this.upcomingLeaveWarningWeeksValue && this.hasRequestedAtWarningTarget) {
      if(this.#isRequestedAtUpcoming(this.fromDateTarget.value, this.requestedAtValue)) {
        this.requestedAtWarningTarget.classList.remove('d-none')
      } else {
        this.requestedAtWarningTarget.classList.add('d-none')
      }
    }
  }

  typeSelectTargetConnected() {
    this.#typeUpdateVisibility()
    this.#allowanceLabelUpdate()
  }

  typeChanged(event) {
    this.typeValue = event.target.value
    this.#allowanceLabelUpdate()

    this.#allowanceUpdateOptions()
  }

  handleFromDateChanged(dateText) {
    this.fromDateValue = dateText
  }

  handleUntilDateChanged(dateText) {
    this.untilDateValue = dateText
  }

  startTimeChanged(event) {
    this.startTimeValue = event.target.value
  }

  endTimeChanged(event) {
    this.endTimeValue = event.target.value
  }

  #allowanceLabelUpdate() {
    const taken = this.typeSelectTarget.value === 'take'
    if(taken) {
      this.takenOrAccruedLabelTarget.innerHTML = 'taken'
    } else {
      this.takenOrAccruedLabelTarget.innerHTML = 'accrued'
    }
  }

  allowanceChanged(_event) {
    this.#setCurrentAllowanceDaysRemaining()
    this.#typeUpdateVisibility()
    this.#processOverbooking()
  }

  #estimateAndUpdateAllowance() {
    if(this.allowanceOverridenValue) {
      return
    }

    const leave_period_attrs = {
      count_all_days: this.typeValue == 'accrue',
      from_date: this.fromDateValue,
      until_date: this.untilDateValue,
      start_time: this.startTimeValue,
      end_time: this.endTimeValue,
      type: this.typeValue,
      units: this.unitsValue
    }

    const estimator = new Eleave.LeavePeriodAllowanceEstimator(leave_period_attrs, this.publicHolidayDatesValue)
    const allowance = estimator.call()

    this.daysTakenTarget.value = allowance

    this.#processOverbooking()
  }

  #allowanceUpdateOptions() {
    const typeValue = this.typeSelectTarget.value
    const allowanceTomSelect = this.allowanceSelectTarget.tomselect

    if(typeValue === 'take') {
      allowanceTomSelect.sync(true)
    } else {
      this.allowanceSelectTarget.querySelectorAll('option').forEach((o) => {
        if(!o.value) {
          return
        }
        const canAccrue = o.dataset.canAccrue === 'true'

        if(!canAccrue) {
          allowanceTomSelect.removeOption(o.value, true)
        }
      })
    }
  }

  #typeUpdateVisibility() {
    const canAccrue = this.allowanceSelectTarget.options[this.allowanceSelectTarget.selectedIndex].dataset.canAccrue === 'true'

    if(canAccrue) {
      this.typeContainerTarget.classList.remove('d-none')
    } else {
      this.typeSelectTarget.value = 'take'
      this.typeContainerTarget.classList.add('d-none')
    }
  }

  // warning, only triggered when the user changes the days taken manually, not triggered when updated by changing date/time
  daysTakenChanged(_event) {
    this.allowanceOverridenValue = true
    this.takenExplanationTarget.classList.add('d-none')

    this.#processOverbooking()
  }

  #isFromDateUpcoming(fromDate) {
    const fromDateMoment = moment(fromDate, 'DD/MM/YYYY')
    const today = moment()
    const weeksFromToday = today.clone().add(this.upcomingLeaveWarningWeeksValue, 'weeks')

    return fromDateMoment.isBetween(today, weeksFromToday, 'days', [])
  }

  #isRequestedAtUpcoming(fromDate, requestedAt) {
    const fromDateMoment = moment(fromDate, 'DD/MM/YYYY')
    //const requestedAtMoment = moment(requestedAt, 'HH:mm on DD/MM/YYYY')
    const requestedAtMoment = moment(requestedAt)

    const weeksFromRequestedAt = requestedAtMoment.clone().add(this.upcomingLeaveWarningWeeksValue, 'weeks')

    return fromDateMoment.isBetween(requestedAtMoment, weeksFromRequestedAt, 'days', [])
  }

  #processOverbooking() {
    if(!this.checkOverbookingValue || !this.hasOverbookingWarningTarget) {
      return
    }

    if(!this.#isAllowanceAllocated()) {
      this.overbookingWarningTarget.classList.add('d-none')
      return
    }

    const currentDaysTaken = parseFloat(this.daysTakenTarget.value) || 0

    // If the current requested days exceed the remaining allowance
    if (this.#isLeavePeriodPersisted()) {
      const persistedDaysTaken = parseFloat(this.daysTakenTarget.getAttribute('value')) || 0

      if (currentDaysTaken > (this.currentAllowanceDaysRemaining + persistedDaysTaken)) {
        this.overbookingWarningTarget.classList.remove('d-none')
      } else {
        this.overbookingWarningTarget.classList.add('d-none')
      }
    } else {
      if (currentDaysTaken > this.currentAllowanceDaysRemaining) {
        this.overbookingWarningTarget.classList.remove('d-none')
      } else {
        this.overbookingWarningTarget.classList.add('d-none')
      }
    }
  }

  #isLeavePeriodPersisted() {
    return this.requestedAtValue !== ''
  }

  #isAllowanceAllocated() {
    return this.currentAllowanceDaysRemaining !== undefined
  }
}
