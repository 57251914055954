import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
//import Rails from '@rails/ujs'

export default class extends Controller {
  static values = { confirmationUrl: String, modalSelector: String }

  // connect() {
  //   console.log('form--confirmation-modal | connect', this.confirmationUrlValue,  this.modalSelectorValue )
  // }

  submitClick(event) {
    //console.log('form--confirmation-modal | submitClick')

    event.stopPropagation()
    event.preventDefault()

    this.#fetchAndDisplayModal()

    //Rails.fire(this.element, 'submit')
  }

  dispatchEventFormUnsavedChangesSkipCheck() {
    //console.log('form--confirmation-modal | dispatchEventFormUnsavedChangesSkipCheck')
    const event_detail = { value: true }
    const event = new CustomEvent('form_unsaved_changes:skip_check', { detail: event_detail })

    window.dispatchEvent(event)
  }

  async #fetchAndDisplayModal() {
    //console.log('form--confirmation-modal | #fetchAndDisplayModal', this.confirmationUrlValue)

    await fetch(this.confirmationUrlValue, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  receiveModalConfirm(_event) {
    //console.log('form--confirmation-modal | receiveModalConfirm()', _event.detail, this.element)

    const isTurboForm = this.element.hasAttribute('data-turbo') && this.element.getAttribute('data-turbo') === 'true'

    if (isTurboForm) {
      // Use Turbo submission for Turbo-enabled forms
      Turbo.navigator.submitForm(this.element);
    } else {
      // Use regular form submission for non-Turbo forms
      this.element.submit();
    }
  }
}
