import { ExpandedHeaderTemplate, SummaryTemplate } from "./templates.js"

export default function ExpandedTableElementTemplate(data) {
  return `
    <div class="session ${data.overlaps_midnight_class}">
      <a class="activity ${data.categorisation.toLowerCase()}"
        href="${data.edit_job_content_path}"
        data-turbo="true"
        data-turbo-prefetch="false"
        data-turbo-frame="section-container">
        <div class="category header">
          ${data.categorisation}
        </div>
        ${ExpandedHeaderTemplate({
          scheduled: data.scheduled,
          start_time: data.start_time,
          end_time: data.end_time
        })}
        <div class="header">
          ${data.hours_long}${data.impacted_hours ? ' *' : ''}
        </div>
        <span class="header_one_line">
          ${data.pas} PAs${data.impacted_pas ? ' *' : ''}
        </span>
        <div>
          ${SummaryTemplate({
            summary: data.summary
          })}
        </div>
      </a>
    </div>
  `
}