export default function TableElementTemplate(data) {

  return `
    <div class="session ${data.overlaps_midnight_class}">
      <a class="activity ${data.categorisation}"
        href="${data.edit_job_content_path}"
        data-turbo="true"
        data-turbo-prefetch="false"
        data-turbo-frame="section-container">
        <span class="header_one_line">
          ${data.hours}${data.impacted_hours ? ' *' : ''}
        </span>
        /
        <span class="header_one_line">
          ${data.pas} PAs${data.impacted_pas ? ' *' : ''}
        </span>
        ${data.one_line_summary}
      </a>
    </div>
  `
}
