// responsible for confirmation modals show
// used e.g. in job plan items destroy/edit/create actions when confirmation needed because signature revoke is involved

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { confirmationUrl: String }

  connect() {
    this.#applyConfirmationModal()

    this.observer = new MutationObserver(() => {
      this.#applyConfirmationModal()
    })

    this.observer.observe(this.element, { childList: true, subtree: true })

    addEventListener('modal:confirm', (event) => {
      this.receiveModalConfirm(event)
    })
  }

  #applyConfirmationModal() {
    const deleteButtons = this.element.querySelectorAll('a[data-turbo-method=delete]')

    // assign confirmation modal to all delete buttons within controller context
    _.each(deleteButtons, (el) => {
      if(el.title == 'Delete Comment' || el.title == 'Delete eDoc') {
        // TODO: requires more work because commentable id and type must be passed similary for eDocs
        return
      }

      //console.log('confirmations | handleUpdate | el', el)
      el.setAttribute('data-action', 'click->confirmations#showConfirmationModal')
      el.setAttribute('data-confirmations-source-action-name-param', 'delete')

      // we don't want default confirmations since we use custom modal in that scenario
      el.removeAttribute('data-turbo-confirm')
      el.removeAttribute('data-confirm')
    })
  }

  showConfirmationModal(event) {
    event.preventDefault()

    // pass source action name, because depending on this, trigger item delete or form post
    this.#fetchAndDisplayModal(event.params.sourceActionName, event.currentTarget.href, event.params.confirmMessage)
  }

  async #fetchAndDisplayModal(source_action_name, source_url, confirm_message) {
    let url = `${this.confirmationUrlValue}?source_action_name=${source_action_name}&source_url=${source_url}`
    if(confirm_message) {
      url += `&confirm_message=${confirm_message}`
    }

    await fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  async receiveModalConfirm(event) {
    const sourceActionName = event.detail.source_action_name
    const sourceUrl = event.detail.source_url

    if(sourceActionName != 'delete') {
      return
    }

    const response = await fetch(sourceUrl, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': this._getCsrfToken(),
        'Accept': 'text/vnd.turbo-stream.html, text/html'
      },
      redirect: 'manual' // Don't automatically follow redirects
    })

    if (response.ok) {
      const html = await response.text()

      if (html.includes('turbo-stream')) {
        Turbo.renderStreamMessage(html)
      } else {
        const url = response.headers.get('X-Redirect-To') || window.location.href
        Turbo.visit(url, { action: "replace" })
      }
    } else {
      console.error('Error response:', response.status)
    }
  }

  _getCsrfToken() {
    return document.querySelector("[name='csrf-token']").getAttribute('content')
  }
}
