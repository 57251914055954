import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { confirmEventName: { type: String, default: 'modal:confirm' } }

  connect() {
    this.modal = new bootstrap.Modal(this.element, {})
    this.modal.show()

    this.element.addEventListener('hidden.bs.modal', function (event) {
      const dataset = event.target.dataset
      //console.log('modal | hidden.bs.modal | dataset', dataset)

      if (dataset.reload) {
        if(dataset.referrer) {
          window.location.href = dataset.referrer
        } else {
          window.location.reload(true)
        }
      }
    }, { once: true })
  }

  // useful when modal rerendered with turbo stream
  disconnect() {
    const instance = bootstrap.Modal.getInstance(this.element)

    if (instance && this.element.classList.contains('show')) {
      //console.log('modal | disconnect | instance', instance)
      instance.hide()
    }
  }

  closeCallback() {
    this.closeModal()
  }

  closeModal() {
    const modal = bootstrap.Modal.getInstance(this.element)
    modal.hide()
  }

  // on click Cancel or Close
  dismiss() {
    this.#dispatchDismiss()
  }

  dispatchConfirm(event) {
    event.preventDefault()

    //console.log('modal | dispatchConfirm | event.params', event.params)

    const event_detail = { confirm_button: event.params.confirmButton,
                           source_action_name: event.params.sourceActionName,
                           source_url: event.params.sourceUrl }

    const event_name = event.params.confirmEventName ? event.params.confirmEventName : this.confirmEventNameValue

    const custom_event = new CustomEvent(event_name, { detail: event_detail })

    //console.log('modal | dispatchConfirm | custom_event', custom_event)

    window.dispatchEvent(custom_event)
  }

  #dispatchDismiss() {
    const custom_event = new CustomEvent('modal:dismiss')
    window.dispatchEvent(custom_event)
  }
}
