import { Controller } from '@hotwired/stimulus'
import autosize from 'autosize' // https://www.jacklmoore.com/autosize/

export default class extends Controller {
  connect() {
    if(this.#isPdf()) {
      // in pdf convert textareas to paragraphs
      const dom_id = this.element.id

      let para = document.createElement('p')
      para.id = dom_id // copy dom_id from converted textarea

      let content = this.element.innerHTML
      content = content.replace(/(?:\r\n|\r|\n)/g, '<br/>')

      para.innerHTML = content
      this.element.replaceWith(para)
    } else {
      autosize(this.element)
    }
  }

  disconnect() {
    if(!this.#isPdf()) {
      autosize.destroy(this.element)
    }
  }

  #isPdf() {
    return document.querySelector('meta[name="pdf"]')
  }
}
