// when attached to turbo form it clears params with empty values and removes 'commit' and 'utf8' params

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitButton', 'resetButton', 'resetScopeCardBody', 'band']
  static values = { submitOnLoad: Boolean }

  connect() {
    if(this.submitOnLoadValue) {
      this.element.requestSubmit()
    }

    // listen to form changes
    this.element.addEventListener('input', this.handleFieldChange.bind(this))
    this.element.addEventListener('change', this.handleFieldChange.bind(this))
    this.element.addEventListener('blur', this.handleFieldChange.bind(this))

    this.element.addEventListener('turbo:submit-start', (event) => {
      this.submitButtonTarget.disabled = true

      this.resetButtonTarget.classList.remove('d-none')

      this.resetScopeCardBodyTargets.forEach(button => {
        button.classList.add('d-none')
      })
    })

    this.element.addEventListener('turbo:submit-end', (event) => {
      this.submitButtonTarget.disabled = true
    })
  }

  disconnect() {
    this.element.removeEventListener('input', this.handleFieldChange.bind(this))
    this.element.removeEventListener('change', this.handleFieldChange.bind(this))
    this.element.removeEventListener('blur', this.handleFieldChange.bind(this))
  }

  handleFieldChange(event, srcElement) {
    if(!srcElement) {
      srcElement = event.srcElement
    }
    this.submitButtonTarget.disabled = false

    const cardScopeEl = srcElement.closest('.card-scope')
    const cardBodyResetEl = cardScopeEl.querySelector('.card-body.reset')
    cardBodyResetEl.classList.remove('d-none')
  }

  resetPartForm(event) {
    event.preventDefault()

    const cardEl = event.target.closest('.card')
    const fieldsToReset = cardEl.querySelectorAll('select, input')
    let defaultValue = null

    fieldsToReset.forEach(field => {
      const tagName = field.tagName.toLowerCase()

      defaultValue = field.dataset.defaultValue

      if(field.tomselect) {
        if(defaultValue) {
          field.tomselect.setValue([defaultValue])
        } else {
          field.tomselect.clear()
        }

      } else if(defaultValue) {
        if(tagName == 'input' && (field.type == 'radio' || field.type == 'checkbox')) {
          field.checked = field.dataset.defaultValue === field.value
        } else {
          field.value = defaultValue
        }
      } else {
        field.value = ''
        field.checked = false
      }
    })

    // hide scope reset button
    const cardScopeEl = event.target.closest('.card-scope')
    const cardBodyResetEl = cardScopeEl.querySelector('.card-body.reset')
    cardBodyResetEl.classList.add('d-none')
  }

  productChangeCallback(event) {
    if(!this.hasBandTarget) {
      return
    }

    const selectElement = event.target
    const selectedValues = Array.from(selectElement.selectedOptions).map(option => option.value);

    if (selectedValues.includes('has_non_medical_job_planning') || selectedValues.length === 0) {
      this.bandTarget.classList.remove('d-none')
    } else {
      this.bandTarget.classList.add('d-none')
    }
  }
}
